@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);
.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-size: 12px;
}

button {
    outline: none !important;
}

#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 10px;
    border: 4px solid #ccc;
    background: #eee;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#calendar-container {
    position: relative;
    z-index: 1;
}

.clearfix {
    /*overflow: auto;*/
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#meeting .fc-unthemed td {
    border-color: #f1f1f1;
    z-index: 2;
    opacity: 1;
}

#time .DateRangePicker {
    z-index: 10;
}

#time .DateRangePickerInput__withBorder {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#time .DateInput_input__small {
    font-size: 1rem;
    color: #000;
}

#time .DateInput__small {
    width: auto !important;
}

#time .DateInput_input__focused {
    outline: none;
    color: #000;
}

.editVote .daterangepicker {
    position: static;
}

.editVote input {
    width: 100%;
    padding: 5px;
    outline: none;
    text-align: center;
}

.meetingSuccses {
    -webkit-animation: succes 1s infinite;
            animation: succes 1s infinite;
    font-size: 13px;
    display: inline-block;


}

@-webkit-keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

@keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

.timeDrag {
    display: none;
}

.fc-mirror .timeDrag {
    display: block;
    font-size: 7px;
}

.colorWhite {
    color: #fff;
}

.colorWhite svg {
    max-width: 15px;
    max-height: 15px;
}

.colorBlack {
    color: #000
}

.colorBlack svg {
    max-width: 15px;
    max-height: 15px;
}

/*svg {*/
/*    max-width: 15px;*/
/*}*/

.div-calendar .fc-body > tr {
    overflow-y: hidden;
}

.div-calendar .fc-body > tr > td {
    border: none;
}

.timeCalendar {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.timeCalendar .about {
    margin: 0 10px;
}

.timeCalendar .bp3-input-group {
    width: 205px;
}

.scrollLeftEvent {
    display: flex;
    /*position: fixed;
    left: 0;
    !*bottom: 0;*!
    height: calc(100vh - 84px);
    top: 85px;*/
    padding: 0px 10px 10px 10px;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.displayScrollLeftEvent {
    /*top: 140px !important;*/
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.leftBar {
    max-width: 270px;
    margin: 10px 5px 0px 0px;
    position: relative
}

.leftHomeApp {
    width: 270px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    padding-right: 10px
}

/*.scrollLeftEvent:hover {*/
/*    overflow: scroll;*/
/*}*/

/*.scrollLeftEvent::-webkit-scrollbar {*/
/*    display: block;*/
/*}*/

.contentEvent {
    display: flex;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.listEvents {
    width: 70%;
    margin: 0 auto;
}

/*.titleChat {*/
/*    line-height: 25px;*/
/*    background: #1a73e8;*/
/*    color: #fff;*/
/*    width: 350px;*/
/*    text-align: left;*/
/*    padding: 10px;*/
/*    border-radius: 8px 8px 0px 0px;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    display: flex;*/
/*}*/

/*.titleChatFocus {*/
/*    line-height: 25px;*/
/*    background: #1a73e8;*/
/*    color: #fff;*/
/*    width: 350px;*/
/*    text-align: left;*/
/*    padding: 10px;*/
/*    border-radius: 8px 8px 0px 0px;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    display: flex;*/
/*    animation: colorBackground 1.5s ease-out infinite;*/
/*    -moz-animation: colorBackground 1.5s ease-out infinite;*/
/*    -webkit-animation: colorBackground 1.5s ease-out infinite;*/
/*    -o-animation: colorBackground 1.5s ease-out infinite;*/

/*}*/

@-webkit-keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1200px) {


    .timeCalendar .bp3-input-group {
        width: auto;
    }

    .listEvents {
        width: 80%;
    }
}

@media screen and (max-width: 960px) {
    .DateRangePicker_picker__directionLeft {
        left: 0 !important;
    }

    /*.titleChat {*/
    /*    width: 250px;*/
    /*}*/
    /*.titleChatFocus {*/
    /*    width: 250px;*/
    /*}*/
    .timeCalendar {
        /*flex-direction: column;*/
    }

    .timeCalendar .about {
        margin: 0 3px;
    }
}

@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}

#calendarRoot .fc-content-skeleton {
    padding-bottom: 0;
}


.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    height: auto !important;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap;
}

body {
    font-family: source-han-sans-japanese, sans-serif !important;
    color: #000000;
    line-height: 1.3;
}

.fontSourceHanSans {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.MuiTypography-root {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.fontBebasNeue {
    font-family: 'Bebas Neue', cursive;
}

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.isWorkingDay {
    color: #000;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;
    /*cursor: pointer;*/
}

.fc-col-header-cell-cushion {
    cursor: default;
}

.DialogEditFreeTime {
    height: 100%;
    max-height: 80vh;
}

.DialogEditFreeTime .headerCalendar {
    cursor: pointer;
}

.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:hover {
    background: #fcf8e3;
}

.div-calendar .fc-time-grid > .fc-bg {
    z-index: 2 !important;
}

.div-calendar .fc-time-grid > .fc-bg .fc-day {
    cursor: pointer;
}

.popNotification {
    position: absolute;
    top: 10px;
    right: 50px
}

.popNotification button:hover {
    background-color: unset;
}

@media screen and (max-width: 600px) {
    .fc-axis {
        width: 12% !important;
    }
}

.ant-btn-clicked:after {
    -webkit-animation: none !important;
    animation: none !important;
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    animation: none !important;
}

/*Custom calendar*/
.fc .fc-timegrid-slot-label-cushion, .fc .fc-timegrid-axis-cushion {
    font-size: 9px !important;
    font-weight: bold;

}

.meeting-app {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.fc-media-screen {
    font-size: 0.8em !important;
}

.fc .fc-timegrid-slot {
    height: unset !important;
}

.check-free {
    position: absolute;
    bottom: 1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.event-hover .check-free-noneClick {
    opacity: 0.4;
    position: absolute;
    bottom: 1px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.event-hover .check-free-noneClick :hover {
    opacity: 0.4;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    background-color: #ffffff;
}

.fc .fc-timegrid-slot-label-cushion {
    background-color: #fff;
    padding: 0 2px 0 8px;
}

.fc .fc-timegrid-slots > table > tbody > tr:nth-child(1) > td:nth-child(1) {
    /*display: none;*/
    padding-top: 5px;
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
    border-color: #f1f1f1 !important;
}

.listProject {
    display: none;
}

.hoverProject:hover .listProject {
    display: block;
}

.hoverProject:hover img {
    margin: auto;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.rootGlobalNav {
    margin-left: 20px;
    background-color: #434343;
    display: flex;
    align-items: center;
    position: relative;
    /*padding: 10px 20px 10px 10px;*/
}

.rootGlobalNav:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #434343;
    -webkit-transform-origin: top right;
            transform-origin: top right;
    -webkit-transform: skew(-23.4deg, 0deg);
            transform: skew(-23.4deg, 0deg);
}

.meeting-app .fc .fc-col-header-cell-cushion {
    padding: 2px;
}

#shareTime .fc-timegrid-cols > table {
    height: 100%;
}

@media screen and (max-width: 768px) {
    .meeting-app .fc .fc-col-header-cell-cushion {
        padding: 0;
    }

}

.menu-dialog .MuiToolbar-regular {
    width: 98%;
}

body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 700;
    font-style: normal;
}

.light {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.medium {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 500;
    font-style: normal;
}

.normal {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 300;
    font-style: normal;
}

.regular {
    font-family: source-han-sans-japanese, sans-serif;
    font-weight: 400;
    font-style: normal;
}

.react-datepicker__input-container input {
    border: none !important;
    width: 100% !important;
}

.collection-create-form_last-form-item {
    margin-bottom: 0;
}

.btnPrimary {
    color: #fff !important;
    background-color: rgb(138, 199, 122) !important;
    border-bottom: solid 3px #79b369 !important;
    text-decoration: none !important;
    border-radius: 5px !important;
    box-shadow: 0 5px 0 0 rgba(121, 179, 105, 0.004) !important;
}

.btnPrimary:active {
    -webkit-transform: translateY(3px) !important;
            transform: translateY(3px) !important;
    border-bottom: none !important;
}

.btnPrimary:disabled {
    /*transform: translateY(3px) !important;*/
    background-color: lightgray !important;
    border-bottom: solid 3px darkgray !important;
}

div[tabindex="-1"]:focus {
    outline: 0;
}

.clf-ant-relative {
    position: relative;
}

.rateOfTask circle {
    display: none;
}

.rateOfTask {
    height: 36px;
}

.w100 {
    width: 100%;
}

.fl-l {
    float: left;
}

.fl-r {
    float: right;
}

.ant-image-preview-img-wrapper img {
    border-radius: 10px !important;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    /*src: url(https://example.com/MaterialIcons-Regular.eot); !* For IE6-8 *!*/
    src: local('Material Icons'),
    local('MaterialIcons-Regular')
    /*url(https://example.com/MaterialIcons-Regular.woff2) format('woff2'),*/
    /*url(https://example.com/MaterialIcons-Regular.woff) format('woff'),*/
    /*url(https://example.com/MaterialIcons-Regular.ttf) format('truetype');*/
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    -webkit-font-feature-settings: 'liga';
            font-feature-settings: 'liga';
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.ItemCheckedCustom h1 {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.fw700 {
    font-weight: 700;
}

.px14 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.brd20 {
    border-radius: 1.25rem;
}

.clf-flex {
    display: flex;
}

.clf-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.clf-grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.clf-block-center {
    display: block;
    justify-content: center;
    align-items: center;
}

.clf-space-between {
    width: 100%;
    justify-content: space-between;
    /*max-width: 210px;*/
}

.clf-bebas-neue {

}

.clf-left-10 {
    margin-left: 10px;
}

.clf-right-10 {
    margin-right: 10px;
}

.clf-cursor-pointer {
    cursor: pointer;
}

.clf-no-border {
    border: none !important;
}

.clf-input {
    width: 50px !important;
}

.clf-input input {
    font-size: 16px;
    font-weight: bold;
}

.clf-svg-color {
    fill: currentColor;
}

.circle-picker {
    justify-content: center;
}

.clf-padding3 {
    padding: 3px !important;
}

@media (max-width: 1600px) {
    .clf-space-between {
        max-width: 210px;
        justify-content: flex-start;
    }
}

.clf-SourceHanSan {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.hover-show-tooltip .ant-tooltip-inner {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 5px;
    /*box-shadow: 1px 1px 3px 3px #9d9b9b;*/
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 180px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 100px !important;
}

.hover-show-tooltip-3 .ant-tooltip-content {
    min-width: 300px !important;
}

.dropdown-nums .ant-dropdown-menu-items {
    padding: 0;
}

/* fullcalendar v5 header custom */
.headerV5 .fc-col-header-cell-cushion {
    width: 100%;
    padding: unset;
}

.headerV5 {
    background: #FFFFFF;
}

.slotV5 {
    border: 1px solid #FFF;
}

.slotLaneV5 {
    border: 1px solid #FFF;
}

.fc th, .fc td {
    border-color: #FFFFFF;
}

.fc-theme-standard .fc-scrollgrid {
    border-color: #FFFFFF;
}

.fc-event-main {
    overflow: hidden;
}

.fc-timegrid-axis {
    background: #FFFFFF;
}

.shareTime .fc-timegrid-event {
    /*opacity: 0.3;*/
}

.mb10 {
    margin-bottom: 10px;
}

.hover-show-tooltip {
    max-width: 400px !important;
}

.ant-message {
    z-index: 1500 !important;
}

