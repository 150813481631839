@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

html, body {
    margin: 0;
    padding: 0;
    font-family: source-han-sans-japanese, sans-serif !important;
    font-size: 12px;
}

button {
    outline: none !important;
}

#external-events {
    z-index: 2;
    top: 20px;
    left: 20px;
    width: auto;
    padding: 0 10px;
    border: 4px solid #ccc;
    background: #eee;
}

#external-events .fc-event {
    margin: 1em 0;
    cursor: move;
}

#calendar-container {
    position: relative;
    z-index: 1;
}

.clearfix {
    /*overflow: auto;*/
}

.clearfix::after {
    content: "";
    clear: both;
    display: table;
}

#meeting .fc-unthemed td {
    border-color: #f1f1f1;
    z-index: 2;
    opacity: 1;
}

#time .DateRangePicker {
    z-index: 10;
}

#time .DateRangePickerInput__withBorder {
    width: 100% !important;
    display: flex;
    flex-direction: row;
    align-items: center;
}

#time .DateInput_input__small {
    font-size: 1rem;
    color: #000;
}

#time .DateInput__small {
    width: auto !important;
}

#time .DateInput_input__focused {
    outline: none;
    color: #000;
}

.editVote .daterangepicker {
    position: static;
}

.editVote input {
    width: 100%;
    padding: 5px;
    outline: none;
    text-align: center;
}

.meetingSuccses {
    animation: succes 1s infinite;
    font-size: 13px;
    display: inline-block;


}

@keyframes succes {
    from {
        color: #000;
    }
    to {
        color: #ff0000;
    }

}

.timeDrag {
    display: none;
}

.fc-mirror .timeDrag {
    display: block;
    font-size: 7px;
}

.colorWhite {
    color: #fff;
}

.colorWhite svg {
    max-width: 15px;
    max-height: 15px;
}

.colorBlack {
    color: #000
}

.colorBlack svg {
    max-width: 15px;
    max-height: 15px;
}

/*svg {*/
/*    max-width: 15px;*/
/*}*/

.div-calendar .fc-body > tr {
    overflow-y: hidden;
}

.div-calendar .fc-body > tr > td {
    border: none;
}

.timeCalendar {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;
}

.timeCalendar .about {
    margin: 0 10px;
}

.timeCalendar .bp3-input-group {
    width: 205px;
}

.scrollLeftEvent {
    display: flex;
    /*position: fixed;
    left: 0;
    !*bottom: 0;*!
    height: calc(100vh - 84px);
    top: 85px;*/
    padding: 0px 10px 10px 10px;
    /*background: #f7f7f7;*/
    box-sizing: border-box;
}

.displayScrollLeftEvent {
    /*top: 140px !important;*/
}

.leftEventMobile {
    position: static;
    min-width: auto;
}

.leftBar {
    max-width: 270px;
    margin: 10px 5px 0px 0px;
    position: relative
}

.leftHomeApp {
    width: 270px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 10px;
    padding-right: 10px
}

/*.scrollLeftEvent:hover {*/
/*    overflow: scroll;*/
/*}*/

/*.scrollLeftEvent::-webkit-scrollbar {*/
/*    display: block;*/
/*}*/

.contentEvent {
    display: flex;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.contentEventMobile {
    margin-left: 0px;
    overflow: auto;
}

.hiddenDisplay {
    display: none;
}

.listEvents {
    width: 70%;
    margin: 0 auto;
}

/*.titleChat {*/
/*    line-height: 25px;*/
/*    background: #1a73e8;*/
/*    color: #fff;*/
/*    width: 350px;*/
/*    text-align: left;*/
/*    padding: 10px;*/
/*    border-radius: 8px 8px 0px 0px;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    display: flex;*/
/*}*/

/*.titleChatFocus {*/
/*    line-height: 25px;*/
/*    background: #1a73e8;*/
/*    color: #fff;*/
/*    width: 350px;*/
/*    text-align: left;*/
/*    padding: 10px;*/
/*    border-radius: 8px 8px 0px 0px;*/
/*    cursor: pointer;*/
/*    position: relative;*/
/*    display: flex;*/
/*    animation: colorBackground 1.5s ease-out infinite;*/
/*    -moz-animation: colorBackground 1.5s ease-out infinite;*/
/*    -webkit-animation: colorBackground 1.5s ease-out infinite;*/
/*    -o-animation: colorBackground 1.5s ease-out infinite;*/

/*}*/

@keyframes colorBackground {
    0% {
        background-color: #fff;
        color: #000;
    }
    50% {
        background-color: #1a73e8;;
        color: #fff;
    }
}

@media screen and (max-width: 1600px) {
}

@media screen and (max-width: 1200px) {


    .timeCalendar .bp3-input-group {
        width: auto;
    }

    .listEvents {
        width: 80%;
    }
}

@media screen and (max-width: 960px) {
    .DateRangePicker_picker__directionLeft {
        left: 0 !important;
    }

    /*.titleChat {*/
    /*    width: 250px;*/
    /*}*/
    /*.titleChatFocus {*/
    /*    width: 250px;*/
    /*}*/
    .timeCalendar {
        /*flex-direction: column;*/
    }

    .timeCalendar .about {
        margin: 0 3px;
    }
}

@media screen and (max-width: 600px) {
    .leftEventMobile, .scrollLeftEvent {
        position: static;
        min-width: auto;
    }

    .contentEvent {
        margin-left: 0px;
    }

    .timeCalendar {
        flex-direction: column;
    }

}

#calendarRoot .fc-content-skeleton {
    padding-bottom: 0;
}


.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
    height: auto !important;
}

.smooth-dnd-container.horizontal {
    display: flex !important;
    flex-wrap: wrap;
}

body {
    font-family: source-han-sans-japanese, sans-serif !important;
    color: #000000;
    line-height: 1.3;
}

.fontSourceHanSans {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.MuiTypography-root {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.fontBebasNeue {
    font-family: 'Bebas Neue', cursive;
}

.isSun {
    color: #ff867f;
}

.isSat {
    color: #1B97F0;
}

.isWorkingDay {
    color: #000;
}

.headerCalendar {
    font-weight: bold;
    position: relative;
    border: solid 1px transparent;
    /*cursor: pointer;*/
}

.fc-col-header-cell-cushion {
    cursor: default;
}

.DialogEditFreeTime {
    height: 100%;
    max-height: 80vh;
}

.DialogEditFreeTime .headerCalendar {
    cursor: pointer;
}

.isSelection {
    border: solid 1px red;
}

.material-icons-fix {
    vertical-align: middle;
}

.div-calendar .fc-time-grid .fc-slats tr td:last-child:hover {
    background: #fcf8e3;
}

.div-calendar .fc-time-grid > .fc-bg {
    z-index: 2 !important;
}

.div-calendar .fc-time-grid > .fc-bg .fc-day {
    cursor: pointer;
}

.popNotification {
    position: absolute;
    top: 10px;
    right: 50px
}

.popNotification button:hover {
    background-color: unset;
}

@media screen and (max-width: 600px) {
    .fc-axis {
        width: 12% !important;
    }
}

.ant-btn-clicked:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

[ant-click-animating-without-extra-node]:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

/*Custom calendar*/
.fc .fc-timegrid-slot-label-cushion, .fc .fc-timegrid-axis-cushion {
    font-size: 9px !important;
    font-weight: bold;

}

.meeting-app {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.fc-media-screen {
    font-size: 0.8em !important;
}

.fc .fc-timegrid-slot {
    height: unset !important;
}

.check-free {
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.event-hover .check-free-noneClick {
    opacity: 0.4;
    position: absolute;
    bottom: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.event-hover .check-free-noneClick :hover {
    opacity: 0.4;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
    text-align: right;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transform: translateY(-50%);
    background-color: #ffffff;
}

.fc .fc-timegrid-slot-label-cushion {
    background-color: #fff;
    padding: 0 2px 0 8px;
}

.fc .fc-timegrid-slots > table > tbody > tr:nth-child(1) > td:nth-child(1) {
    /*display: none;*/
    padding-top: 5px;
}

.fc-theme-standard td, .fc-theme-standard th, .fc-theme-standard .fc-scrollgrid {
    border-color: #f1f1f1 !important;
}

.listProject {
    display: none;
}

.hoverProject:hover .listProject {
    display: block;
}

.hoverProject:hover img {
    margin: auto;
    transform: rotate(180deg);
}

.rootGlobalNav {
    margin-left: 20px;
    background-color: #434343;
    display: flex;
    align-items: center;
    position: relative;
    /*padding: 10px 20px 10px 10px;*/
}

.rootGlobalNav:before {
    content: " ";
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: #434343;
    transform-origin: top right;
    transform: skew(-23.4deg, 0deg);
}

.meeting-app .fc .fc-col-header-cell-cushion {
    padding: 2px;
}

#shareTime .fc-timegrid-cols > table {
    height: 100%;
}

@media screen and (max-width: 768px) {
    .meeting-app .fc .fc-col-header-cell-cushion {
        padding: 0;
    }

}
