.fw700 {
    font-weight: 700;
}

.px14 {
    font-size: 0.875rem;
    line-height: 1rem;
}

.brd20 {
    border-radius: 1.25rem;
}

.clf-flex {
    display: flex;
}

.clf-flex-center {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.clf-grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.clf-block-center {
    display: block;
    justify-content: center;
    align-items: center;
}

.clf-space-between {
    width: 100%;
    justify-content: space-between;
    /*max-width: 210px;*/
}

.clf-bebas-neue {

}

.clf-left-10 {
    margin-left: 10px;
}

.clf-right-10 {
    margin-right: 10px;
}

.clf-cursor-pointer {
    cursor: pointer;
}

.clf-no-border {
    border: none !important;
}

.clf-input {
    width: 50px !important;
}

.clf-input input {
    font-size: 16px;
    font-weight: bold;
}

.clf-svg-color {
    fill: currentColor;
}

.circle-picker {
    justify-content: center;
}

.clf-padding3 {
    padding: 3px !important;
}

@media (max-width: 1600px) {
    .clf-space-between {
        max-width: 210px;
        justify-content: flex-start;
    }
}

.clf-SourceHanSan {
    font-family: source-han-sans-japanese, sans-serif !important;
}

.hover-show-tooltip .ant-tooltip-inner {
    background-color: #FFFFFF;
    border-radius: 10px;
    padding: 5px;
    /*box-shadow: 1px 1px 3px 3px #9d9b9b;*/
}

.hover-show-tooltip .ant-tooltip-arrow-content {
    background-color: #F1F1F1;
}

.hover-show-tooltip .ant-tooltip-content {
    min-width: 180px;
}

.hover-show-tooltip-2 .ant-tooltip-content {
    min-width: 100px !important;
}

.hover-show-tooltip-3 .ant-tooltip-content {
    min-width: 300px !important;
}

.dropdown-nums .ant-dropdown-menu-items {
    padding: 0;
}

/* fullcalendar v5 header custom */
.headerV5 .fc-col-header-cell-cushion {
    width: 100%;
    padding: unset;
}

.headerV5 {
    background: #FFFFFF;
}

.slotV5 {
    border: 1px solid #FFF;
}

.slotLaneV5 {
    border: 1px solid #FFF;
}

.fc th, .fc td {
    border-color: #FFFFFF;
}

.fc-theme-standard .fc-scrollgrid {
    border-color: #FFFFFF;
}

.fc-event-main {
    overflow: hidden;
}

.fc-timegrid-axis {
    background: #FFFFFF;
}

.shareTime .fc-timegrid-event {
    /*opacity: 0.3;*/
}

.mb10 {
    margin-bottom: 10px;
}

.hover-show-tooltip {
    max-width: 400px !important;
}

.ant-message {
    z-index: 1500 !important;
}
